import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useAppConfig from '@core/app-config/useAppConfig';
import {mapGetters} from "vuex";

export const billingMixins = {
    data() {
        return {
            config: useAppConfig(),
            disableLoading: '',
        };
    },

    computed: {
        message() {
            return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
        },
        getLang() {
            return this.config.lang.value;
        },
        ...mapGetters({
            GET_LOCALE: 'appConfig/getLocale'
        })
    },
    methods: {
        updatePackage(uuid, status, params) {
            return new Promise((resolve, reject) => {
                this.$useJwt
                    .updatePackages(uuid, status, params)
                    .then(res => {
                        this.popupMsg(
                            this.$t('Message.Success'),
                            res.data[this.message],
                            'CheckIcon',
                            'success'
                        );
                        resolve(true);
                    })
                    .catch(error => {
                        if (error.response) {
                            this.popupMsg(
                                this.$t('Message.Failed'),
                                error.response.data[this.message],
                                'AlertTriangleIcon',
                                'danger'
                            );
                            reject(true);
                        }
                    });
            });
        },

        async updatePaymentPlan(value, loadingState, message = null) {
            const planInfo = await this.getPlans();
            const subUUID = await this.getUuid()

            loadingState = true;
            let plan = value ? 'quarterly' : 'yearly';

            const filterPlan = planInfo.results.filter(el => el.name.toLowerCase() === plan.toLowerCase())
            if (filterPlan.length > 0) {
                return new Promise((resolve, reject) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: this.$t('Message.wait'),
                            variant: 'warning',
                            text: this.$t('Message.subscriptionProcess'),
                            waiting: true,
                        },
                    });
                    this.$useJwt
                        .changePaymentPlan({
                            id: subUUID,
                            payment_plan_uuid: filterPlan[0].uuid,
                        })
                        .then(res => {
                            this.popupMsg(
                                this.$t('Message.Success'),
                                res.data[this.message],
                                'CheckIcon',
                                'success'
                            );
                            resolve(false);
                        })
                        .catch(err => {
                            this.popupMsg(
                                this.$t('Message.Failed'),
                                err.response.data[message],
                                'AlertTriangleIcon',
                                'danger'
                            );
                        })
                });
                return
            }

        },

        getBillingInformation() {
            return this.$useJwt.getBillingsDetail();
        },
        getBillingHistory() {
            return this.$useJwt.getBillingsHistory();
        }
    },
};
